import { RouteLocationNormalized } from 'vue-router';

import { router } from '@/router/router';
import { Router, RouterResolver, Routes } from '@/router/types';
import { useSplit } from '@/services/growth-book/hooks/use-split/use-split';

export const historyPaths: string[] = [];
const canonical = document.createElement('link');
canonical.rel = 'canonical';
canonical.href = '';
document.head.appendChild(canonical);

export const resolveRoute: RouterResolver<Routes> = (name, params?) => {
  const info = router.resolve({
    name,
    params,
  });
  const fullPath = `${window.location.origin}${info.href}`;

  return {
    ...info,
    fullPath,
  };
};

export const pushRoute: Router<Routes> = (name, params?) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { fullPath } = resolveRoute(name, params);

  if (router.currentRoute.value.fullPath !== fullPath) {
    if (name === 'activity') {
      const splitActivity2 = useSplit('activity2');
      splitActivity2.init().then(() => {
        if (splitActivity2.variant === 'v2') {
          router.push({
            name: 'activityName',
            params: {
              name: 'like',
            },
          });
        } else {
          router.push({ name, params }).catch((e) => {
            console.log('router.push error', e);
          });
        }
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      router.push({ name, params }).catch((e) => {
        console.log('router.push error', e);
      });
    }
  }
};

// Target router wouldn't be in the history.
export const replaceRoute: Router<Routes> = async (name, params?) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { fullPath } = resolveRoute(name, params);
  if (router.currentRoute.value.fullPath !== fullPath) {
    await router.replace({ name, params });
    historyPaths.pop();
  }
};

// Current router wouldn't be in the history.
export const replacePrevRoute: Router<Routes> = (name, params?) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { fullPath } = resolveRoute(name, params);
  if (router.currentRoute.value.fullPath !== fullPath) {
    historyPaths.pop();
    router.replace({ name, params });
  }
};

export function lastHistoryPathEqualsCurrent(): boolean {
  return historyPaths[historyPaths.length - 1] === window.location.pathname;
}

export function canGoBack(): boolean {
  if (lastHistoryPathEqualsCurrent()) {
    return historyPaths.length > 1;
  }

  return historyPaths.length >= 1;
}

export function goBack(): void {
  if (lastHistoryPathEqualsCurrent()) {
    historyPaths.pop();
  }

  const path = historyPaths.slice(-1)[0];

  if (path) {
    router.push(path);
  }
}

export const goBackOrGo: Router<Routes> = (name, params?) => {
  // Check if we have history on our site, to prevent leaving site,
  // if we have come from another one.
  if (canGoBack()) {
    goBack();
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    pushRoute(name, params);
  }
};

export function getRoute() {
  return router.currentRoute.value;
}

export function isSignupRoute(to: RouteLocationNormalized): boolean {
  return to.fullPath.indexOf('/signup') === 0;
}
