import { reactive, ref } from 'vue';

import { myProfileApi } from '@/services/api/my-profile/my-profile-api';

type UseSelfArg = {
  //
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function useSelf(arg: UseSelfArg) {
  // Ref -----------------------------------------------------------------------
  const url = ref('');

  // Hook ----------------------------------------------------------------------

  // Computed ------------------------------------------------------------------

  // Method --------------------------------------------------------------------
  async function fetchReviewFormUrl() {
    url.value = (await myProfileApi.getTrustPilotReviewFormURL()).url;
  }

  function init() {
    fetchReviewFormUrl();
    myProfileApi.submitTrustPilotReview();
  }

  return reactive({
    url,
    init,
  });
}
