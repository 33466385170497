<template>
  <NotificationActivity :category="'visit'" @action="goPage">
    <template #icon>
      <NotificationActivityIcon :name="'eye_solid'" />
    </template>
    <template #avatar>
      <NotificationActivityAvatar :is-locked="true" :form="'square'" :photo-id="info.avatar.id" />
    </template>
    <template #header>{{ t('newVisit', { name: info.name }) }}</template>
    <template #description>
      {{ t('youHaveSomeThings') }}
    </template>
    <template #action>{{ t('meet', { name: info.name }) }}</template>
  </NotificationActivity>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';

import NotificationActivityAvatar from '@/components/notification/components/notification-redesign/notification-activity/components/notification-activity-avatar/NotificationActivityAvatar.vue';
import NotificationActivityIcon from '@/components/notification/components/notification-redesign/notification-activity/components/notification-activity-icon/NotificationActivityIcon.vue';
import NotificationActivity from '@/components/notification/components/notification-redesign/notification-activity/NotificationActivity.vue';
import { NotificationDataVisit } from '@/components/notification/helpers/notification-shower/notification-shower';
import { hasPremium } from '@/helpers/feature/feature';
import { customEventTrigger } from '@/helpers/gtm-triggers/gtm-triggers';
import { pushRoute } from '@/router';
import { useSplit } from '@/services/growth-book/hooks/use-split/use-split';
import { useI18n } from '@/services/i18n';
import { hideAllModals } from '@/services/modal/modal';

import { messages } from './i18n';

const props = defineProps({
  info: {
    type: Object as PropType<NotificationDataVisit>,
    required: true,
  },
});

const { t } = useI18n({ messages });

function goPage(): void {
  if (hasPremium()) {
    pushRoute('profileView', { userId: props.info.visitorUserId });
  } else {
    const splitActivity2 = useSplit('activity2');
    splitActivity2.init().then(() => {
      if (splitActivity2.variant === 'v2') {
        pushRoute('activityName', {
          name: 'visit',
        });
      } else {
        pushRoute('activity', {
          source: 'incoming',
        });
      }
    });
  }
  hideAllModals();
  customEventTrigger({
    event: 'notification_visit_click',
  });
}
</script>
