import { NotificationDialogMessageV2_DialogMessage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/notification/v1/notification_pb';
import { PremiumPackage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { ProfilePhoto } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/profile/v1/profile_pb';

import { notificationAstroCompatibilityPromo } from '@/components/notification/components/notification-activity/helpers/notification-astro-compatibility-promo/notification-astro-compatibility-promo';
import { notificationSexualCompatibilityPromo } from '@/components/notification/components/notification-activity/helpers/notification-sexual-compatibility-promo/notification-sexual-compatibility-promo';
import { showNotification } from '@/components/notification/helpers/notification-shower/notification-shower';
import { setLastPurchasedPackage } from '@/components/payment/helpers/payment-method/payment-method';
import { setEarlyUpgradeModalTimeLastShown } from '@/components/prolongation-modal/components/prolongation-early-upgrade-modal/helpers/early-upgrade-modal/early-upgrade-modal';
import { trustpilotModal } from '@/components/trustpilot-modal/helpers/trustpilot-modal/trustpilot-modal';
import { toggleActivityFavicon } from '@/helpers/favicon/favicon';
import { playSound } from '@/helpers/sound/sound';
import { useActivityIncomingDot } from '@/pages/activity/components/activity-incoming/hooks/use-activity-incoming-dot/use-activity-incoming-dot';
import usePossibilities from '@/pages/my-profile/components/my-profile-view/components/my-profile-boosters-grid/components/profile-card/components/profile-card-item/hooks/use-possibilities/use-possibilities';
import { showTrialUpsaleModal } from '@/pages/payment/components/purchase/components/purchase-promo/components/trial-upsale-modal/helpers/trial-upsale/trial-upsale';
import { trackConversion } from '@/pages/payment/helpers/payment-websocket/helpers/conversion/conversion';
import { RouteName, router } from '@/router';
import {
  pushNotificationManagerAstroVerification,
  pushNotificationManagerChatMessage,
  pushNotificationManagerIncomingMatch,
  pushNotificationManagerLike,
  pushNotificationManagerRomanticVerification,
  pushNotificationManagerVisit,
} from '@/services/notification-manager/helpers/push/push';
import { NotificationHandler } from '@/services/websocket';
import { commit, dispatch } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

export function showNotificationNewMessage(
  dialogId: string,
  message: ExtractClassFields<NotificationDialogMessageV2_DialogMessage>,
) {
  if ((router.currentRoute.value.name as RouteName) !== 'chatView') {
    toggleActivityFavicon(true);
    pushNotificationManagerChatMessage({
      type: 'message',
      dialogId,
      message,
    });
  }
}

export const notificationNotificationHandler: NotificationHandler = async (notification) => {
  switch (notification.notification.case) {
    case 'matchFromUser': {
      const matchFromUser = notification.notification.value;

      if (matchFromUser && !matchFromUser.isPowerLike) {
        commit('mutationProfileUnlockChat', {
          chatId: matchFromUser.dialogId,
          userId: matchFromUser.userId,
        });

        const avatar = matchFromUser.avatar;

        pushNotificationManagerIncomingMatch({
          type: 'match',
          dialogId: matchFromUser.dialogId,
          name: matchFromUser.name,
          age: matchFromUser.age,
          isOnline: matchFromUser.isOnline,
          userId: matchFromUser.userId,
          avatar: {
            id: avatar?.id ?? '',
            hasBlur: avatar?.hasBlur ?? false,
            declineReason: avatar?.declineReason ?? '',
            isDeclined: avatar?.isDeclined ?? false,
            isDummy: avatar?.isDummy ?? false,
            tags: avatar?.tags ?? [],
          },
        });

        dispatch('actionProfileGetConnectionState', matchFromUser.userId);
      }

      break;
    }

    case 'like': {
      const like = notification.notification.value;

      if (like) {
        const avatar = like.avatar;

        // playSound('notification');
        toggleActivityFavicon(true);

        pushNotificationManagerLike({
          type: 'like',
          userId: like.userId,
          gender: like.gender,
          age: like.age,
          name: like.name,
          avatar: {
            id: avatar?.id ?? '',
            hasBlur: avatar?.hasBlur ?? false,
            declineReason: avatar?.declineReason ?? '',
            isDeclined: avatar?.isDeclined ?? false,
            isDummy: avatar?.isDummy ?? false,
            tags: avatar?.tags ?? [],
          },
        });

        useActivityIncomingDot().setHasActivity(true);
      }

      break;
    }

    case 'visit': {
      const visit = notification.notification.value;

      if (visit) {
        const avatar = visit.avatar;

        // playSound('notification');
        toggleActivityFavicon(true);

        pushNotificationManagerVisit({
          type: 'visit',
          visitorUserId: visit.visitorUserId,
          age: visit.visitorAge,
          name: visit.visitorName,
          avatar: {
            id: avatar?.id ?? '',
            hasBlur: avatar?.hasBlur ?? false,
            declineReason: avatar?.declineReason ?? '',
            isDeclined: avatar?.isDeclined ?? false,
            isDummy: avatar?.isDummy ?? false,
            tags: avatar?.tags ?? [],
          },
        });

        useActivityIncomingDot().setHasActivity(true);
      }

      break;
    }

    case 'requestPhoto': {
      const requestPhotoNotification = notification.notification.value;

      if (requestPhotoNotification) {
        const avatar = requestPhotoNotification.avatar;
        playSound('notification');
        toggleActivityFavicon(true);

        showNotification({
          type: 'request-photo',
          userId: requestPhotoNotification.userId,
          age: requestPhotoNotification.age,
          name: requestPhotoNotification.name,
          avatar: {
            id: avatar?.id ?? '',
            hasBlur: avatar?.hasBlur ?? false,
            declineReason: avatar?.declineReason ?? '',
            isDeclined: avatar?.isDeclined ?? false,
            isDummy: avatar?.isDummy ?? false,
            tags: avatar?.tags ?? [],
          },
        });

        useActivityIncomingDot().setHasActivity(true);
      }

      break;
    }

    case 'requestPhotoUploaded': {
      const requestPhotoUploadedNotification = notification.notification.value;

      if (requestPhotoUploadedNotification) {
        const avatar = requestPhotoUploadedNotification.avatar;
        playSound('notification');
        toggleActivityFavicon(true);

        showNotification({
          type: 'request-photo-uploaded',
          userId: requestPhotoUploadedNotification.userId,
          age: requestPhotoUploadedNotification.age,
          name: requestPhotoUploadedNotification.name,
          avatar: {
            id: avatar?.id ?? '',
            hasBlur: avatar?.hasBlur ?? false,
            declineReason: avatar?.declineReason ?? '',
            isDeclined: avatar?.isDeclined ?? false,
            isDummy: avatar?.isDummy ?? false,
            tags: avatar?.tags ?? [],
          },
        });
      }

      break;
    }

    case 'boosterActive': {
      const data = notification.notification.value;
      const duration = Number(data.duration?.seconds ?? 0);
      const { requestData } = usePossibilities();

      if (duration) {
        commit('mutationMyProfileShortChangeBoosterEndsDuration', duration * 1000);
      }

      showNotification({
        type: 'booster-activated',
      });
      dispatch('actionMyProfileGetShort');
      requestData();

      break;
    }

    case 'creditsRefillSuccess': {
      showNotification({
        type: 'credits-added',
      });

      showTrialUpsaleModal();
      break;
    }

    case 'subscriptionPaymentSuccess': {
      const success = notification.notification.value;
      const identity = success.package;
      let lastPurchasedPkg: ExtractClassFields<PremiumPackage>;

      if (identity) {
        lastPurchasedPkg = (await dispatch(
          'actionPaymentGetLastPurchasedPackage',
          identity,
        )) as ExtractClassFields<PremiumPackage>;

        if (lastPurchasedPkg.isTrial) {
          // trackFacebookPixel({ event: 'take_trial' });
          // trackFacebookPixel({ event: 'take_trial_2' });
          trackConversion('take_trial');

          // https://gdx.myjetbrains.com/youtrack/issue/GDP-3550/Clone-paypal-events-request
          // if (success.descriptorText === 'paypal.com') {
          //   if (getCache(PIXEL_ID_CACHE_KEY) === '2754010231487281') {
          //     dispatch('actionMyProfileGetFull').then((profile) => {
          //       if (profile.age >= 50) {
          //         trackFacebookPixel({ event: 'take_trial', isSecondEvent: true });
          //       }
          //     });
          //   }
          // }
        } else if (lastPurchasedPkg.nextPackage) {
          trackConversion('subscribed');
        }

        if (lastPurchasedPkg?.price?.totalAmount !== 0n) {
          setLastPurchasedPackage(lastPurchasedPkg);
        }
      }
      setEarlyUpgradeModalTimeLastShown();
      showNotification({
        type: 'subscription-payment-success',
      });

      break;
    }

    case 'subscriptionPaymentFailed': {
      const failed = notification.notification.value;
      const identity = failed.package;

      if (identity) {
        const lastPurchasedPkg = await dispatch('actionPaymentGetLastPurchasedPackage', identity);

        commit(
          'mutationPaymentSetLastPurchasedPackage',
          lastPurchasedPkg as ExtractClassFields<PremiumPackage>,
        );
      }

      showNotification({
        type: 'subscription-payment-failed',
      });

      break;
    }

    case 'astroCompatibilityPromo': {
      const response = notification.notification.value;
      const userId = response.subjectId;
      const avatar = response.avatar;

      notificationAstroCompatibilityPromo(userId, avatar as ExtractClassFields<ProfilePhoto>);

      break;
    }

    case 'sexualCompatibilityPromo': {
      const response = notification.notification.value;
      const userId = response.subjectId;
      const avatar = response.avatar;

      notificationSexualCompatibilityPromo(userId, avatar as ExtractClassFields<ProfilePhoto>);

      break;
    }

    case 'astroCompatibility': {
      const response = notification.notification.value;
      const userId = response.subjectId;
      const avatar = response.avatar;
      const name = response.subjectName;
      const profile = {
        avatar: avatar as ExtractClassFields<ProfilePhoto>,
        name,
        userId,
      };

      pushNotificationManagerAstroVerification(profile);
      useActivityIncomingDot().setHasActivity(true);
      break;
    }

    case 'sexualCompatibility': {
      const response = notification.notification.value;
      const userId = response.subjectId;
      const avatar = response.avatar;
      const name = response.subjectName;
      const profile = {
        avatar: avatar as ExtractClassFields<ProfilePhoto>,
        name,
        userId,
      };

      pushNotificationManagerRomanticVerification(profile);
      useActivityIncomingDot().setHasActivity(true);
      break;
    }

    case 'reviewPopupEvent': {
      trustpilotModal.show();
      break;
    }

    default:
      break;
  }
};
