import { SearchRequest } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/profile/v1/profile_api_pb';
import {
  ProfileChildren,
  ProfileComfortRadiusUnits,
  ProfileGender,
  ProfileHeight,
  ProfileHeightUnits,
  ProfileLocation,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/profile/v1/profile_pb';
import {
  SearchCriteria,
  SearchLocation,
} from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/profile/v1/search_pb';

import { SearchStateFilter, SearchStateResultProfile } from '@/pages/search/store/search-state';
import { search } from '@/services/api/profile/profile-api';
import {
  SETTINGS_PROFILE_DEFAULT_AGE_FROM,
  SETTINGS_PROFILE_DEFAULT_AGE_TO,
  SETTINGS_PROFILE_DEFAULT_CITY_NAME,
  SETTINGS_PROFILE_DEFAULT_COUNTRY_NAME,
  SETTINGS_PROFILE_DEFAULT_HEIGHT_FROM,
  SETTINGS_PROFILE_DEFAULT_HEIGHT_TO,
  SETTINGS_PROFILE_DEFAULT_LOCATION_LATITUDE,
  SETTINGS_PROFILE_DEFAULT_LOCATION_LONGITUDE,
} from '@/settings';

export type SearchResult = {
  profiles: SearchStateResultProfile[];
  filter: SearchStateFilter;
};

export const SEARCH_LIMIT = 50;

export function getSearchCriteria(filter: SearchStateFilter): SearchCriteria | undefined {
  if (filter.isEmptySearch) {
    return;
  }

  const profileLocation = new ProfileLocation({
    latitude: filter.location.latitude,
    longitude: filter.location.longitude,
    city: filter.location.city,
    country: filter.location.country,
    state: filter.location.state,
  });

  const location = new SearchLocation({
    radiusUnits: ProfileComfortRadiusUnits.MI,
    location: profileLocation,
  });

  const criteria = new SearchCriteria({
    gender: filter.gender,
    ageFrom: filter.ageFrom,
    ageTo: filter.ageTo,
    location,
    isNew: filter.isNew,
    isOnline: filter.isOnline,
    smoking: filter.smoking,
    drinking: filter.drinking,
    maritalStatus: filter.maritalStatus,
    children: [filter.children],
    sexualOrientation: filter.sexualOrientation,
    religion: filter.religion,
    ethnicity: filter.ethnicity,
    bodyType: filter.bodyType,
    hairColor: filter.hairColor,
    eyeColor: filter.eyeColor,
    sport: filter.sport,
    lookingFor: filter.lookingFor,
    education: filter.education,
    sphereOfWork: filter.sphereOfWork,
    interests: filter.interests,
  });

  if (typeof filter.hasPhotos !== 'undefined') {
    criteria.hasPhotos = filter.hasPhotos;
  }

  return criteria;
}

export function getSearchResult(searchFrom = '', criteria?: SearchCriteria): Promise<SearchResult> {
  const request = new SearchRequest({
    searchFrom,
    limit: SEARCH_LIMIT,
    criteria,
  });

  return search(request).then((response) => {
    const criteria = response.criteria;
    const location = criteria?.location;
    const result: SearchResult = {
      profiles: [],
      filter: {
        isEmptySearch: false,
        gender: criteria?.gender ?? ProfileGender.INVALID,
        ageFrom: criteria?.ageFrom ?? SETTINGS_PROFILE_DEFAULT_AGE_FROM,
        ageTo: criteria?.ageTo ?? SETTINGS_PROFILE_DEFAULT_AGE_TO,
        location: {
          ...(location?.location ?? {
            latitude: SETTINGS_PROFILE_DEFAULT_LOCATION_LATITUDE,
            longitude: SETTINGS_PROFILE_DEFAULT_LOCATION_LONGITUDE,
            country: SETTINGS_PROFILE_DEFAULT_COUNTRY_NAME,
            city: SETTINGS_PROFILE_DEFAULT_CITY_NAME,
            state: '',
          }),
        },
        isNew: criteria?.isNew ?? false,
        isOnline: criteria?.isOnline ?? false,
        smoking: criteria?.smoking ?? [],
        drinking: criteria?.drinking ?? [],
        maritalStatus: criteria?.maritalStatus ?? [],
        children: criteria?.children[0] ?? ProfileChildren.INVALID,
        sexualOrientation: criteria?.sexualOrientation ?? [],
        religion: criteria?.religion ?? [],
        ethnicity: criteria?.ethnicity ?? [],
        bodyType: criteria?.bodyType ?? [],
        hairColor: criteria?.hairColor ?? [],
        eyeColor: criteria?.eyeColor ?? [],
        sport: criteria?.sport ?? [],
        lookingFor: criteria?.lookingFor ?? [],
        education: criteria?.education ?? [],
        sphereOfWork: criteria?.sphereOfWork ?? [],
        interests: criteria?.interests ?? [],
      },
    };

    response.profiles.forEach((profile) => {
      const avatar = profile.avatar;
      const location = profile.location;
      const photos = profile.photos;

      result.profiles.push({
        id: profile.id,
        name: profile.name,
        cardId: profile.cardId,
        age: profile.age,
        distance: profile.distance,
        locationName: profile.locationName,
        avatar: {
          id: avatar?.id ?? '',
          hasBlur: avatar?.hasBlur ?? false,
          declineReason: avatar?.declineReason ?? '',
          isDeclined: avatar?.isDeclined ?? false,
          isDummy: avatar?.isDummy ?? false,
          tags: avatar?.tags ?? [],
        },
        isNew: profile.isNew,
        isVerified: profile.isVerified,
        isOnline: profile.isOnline,
        isHighlighted: profile.isHighlighted,
        searchPosition: profile.searchPosition,
        isLiked: profile.isLiked,
        location: {
          city: location?.city ?? '',
          country: location?.country ?? '',
          latitude: location?.latitude ?? 0,
          longitude: location?.longitude ?? 0,
          state: location?.state ?? '',
        },
        photos,
      });
    });

    return result;
  });
}

// eslint-disable-next-line consistent-return
export function getLastSearchPosition(profiles: SearchStateResultProfile[]): string {
  if (profiles.length) {
    return profiles[profiles.length - 1].searchPosition;
  }
  return '';
}
